import { ResponsiveBar } from "@nivo/bar";

export const Charts = ({ data, height = 600, color = "black" }) => {
	return (
		<div style={{ fontFamily: "Rokkitt", width: "100%", height: height }}>
			<ResponsiveBar
				theme={{
					text: {
						fontFamily: "Rokkitt",
						fontSize: 20,
						fill: color,
					},
				}}
				data={data}
				keys={["punts"]}
				indexBy="nom"
				margin={{ top: -20, right: 20, bottom: 40, left: 130 }}
				padding={0.1}
				valueScale={{ type: "linear" }}
				indexScale={{ type: "band", round: true }}
				colors={{ scheme: "nivo" }}
				layout="horizontal"
				defs={[
					{
						id: "lines",
						type: "patternLines",
						background: "inherit",
						color: "#C7BDAF",
						rotation: -45,
						lineWidth: 7,
						spacing: 10,
					},
				]}
				fill={[
					{
						match: {
							id: "punts",
						},
						id: "lines",
					},
				]}
				borderColor={{
					from: "color",
					modifiers: [["darker", 1.6]],
				}}
				axisTop={null}
				axisRight={null}
				axisBottom={{
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 0,
					legendPosition: "middle",
					legendOffset: 32,
					truncateTickAt: 0,
				}}
				axisLeft={{
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 0,
					legendPosition: "middle",
					legendOffset: -40,
					truncateTickAt: 0,
					renderTick: ({ x, y, textAnchor, textX, textY, value }) => (
						<g transform={`translate(${x},${y})`}>
							<text
								textAnchor={textAnchor}
								transform={`translate(${textX},${textY + 5})`} // places label 5px lower than origin place
								style={{ fontSize: 23, fontFamily: "Rokkitt", fill: color }}
							>
								{value}
							</text>
						</g>
					),
				}}
				labelSkipWidth={12}
				labelSkipHeight={12}
				labelTextColor={{
					from: "color",
					modifiers: [["darker", 1.6]],
				}}
				role="application"
				ariaLabel="Nivo bar chart demo"
			/>
		</div>
	);
};
