import React, { forwardRef, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { Fade } from "@mui/material";
import theme from "../theme";
import Title from "./Title";
import { Box } from "@mui/system";

const PageAdmin = forwardRef(({ children, title = "", button, icon, noMargin, ...rest }, ref) => {
	const [appear, setApperar] = useState(false);
	useEffect(() => {
		window.scrollTo(0, 0);
		setTimeout(() => {
			setApperar(true);
		}, 300);
	}, []);

	return (
		<div
			ref={ref}
			{...rest}
			style={{
				position: "relative",
			}}
		>
			<Helmet>
				<title>Insomnia Corp - {title}</title>
			</Helmet>
			<Title button={button} icon={icon}>
				{title}
			</Title>
			<Fade in={appear} timeout={400}>
				<div
					style={{
						overflow: "hidden",
						backgroundColor: theme.color.background_dark,
					}}
				>
					<Box m={noMargin ? 0 : 3} mt={noMargin ? 9 : 13} zIndex={0}>
						{children}
					</Box>
				</div>
			</Fade>
		</div>
	);
});

PageAdmin.propTypes = {
	children: PropTypes.node.isRequired,
	title: PropTypes.string,
	button: PropTypes.object,
};

export default PageAdmin;
