import { Add, EmojiEvents, Fullscreen, Link, Remove } from "@mui/icons-material";
import { Avatar, Divider, Grid, IconButton, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import moment from "moment";
import React, { useEffect, useState } from "react";
import "moment/locale/ca";
import "moment/min/moment-with-locales";
import { useNavigate } from "react-router";
import PageAdmin from "../../components/PageAdmin";
import Loading from "../../components/Loading";
import { Equips, Temps } from "../../db/firebase";
import { useCountdown } from "../../components/countdown";
import Accions from "../../components/Accions";
import { Charts } from "../../components/Chart";
import theme from "../../theme";
import { DialogResultat } from "../../components/DialogResultat";

function HomeAdmin() {
	const [loading, error, equips, getEquips, sumarPunts] = Equips();
	const [temps, getTemps, addMinuts] = Temps();
	const [puntsTotals, setPuntsTotals] = useState(0);
	const classes = useStyles();
	const [openResultats, setOpenResultats] = useState(false);
	const [days, hours, minutes, seconds] = useCountdown(moment(temps).format("YYYY-MM-DD HH:mm:ss"));

	useEffect(() => {
		getEquips();
		getTemps();
	}, []);

	useEffect(() => {
		let total = 0;
		equips?.map((e) => (total += Number(e?.punts)));
		setPuntsTotals(total);
	}, [equips]);

	const addT = (minutes) => {
		addMinuts(minutes);
	};

	const tempsAcabat = () => {
		return hours <= 0 && minutes <= 0 && seconds <= 0;
	};

	return (
		<PageAdmin title="El secreto de los Krugger">
			<Loading loading={loading}>
				<Grid container spacing={3}>
					<Grid item md={8}>
						<Box className={classes.bloc} mb={3}>
							<Typography variant="h3" mb={2}>
								Equipos
							</Typography>
							<Grid container spacing={3}>
								{equips
									?.sort(function (a, b) {
										return a.nom > b.nom;
									})
									?.map((equip, index) => (
										<Grid item md={2.4}>
											<Box className={classes.equip}>
												<Box display={"flex"} justifyContent={"space-between"}>
													<Box display={"flex"}>
														<Typography ml={1} variant="h3">
															{equip.nom}
														</Typography>
													</Box>
													<Typography fontWeight={700}>{equip.punts}</Typography>
												</Box>
												<Stack direction={"row"} spacing={1} justifyContent={"center"} mt={1}>
													<Box className={classes.temps} onClick={() => sumarPunts(equip.id, -50)}>
														-50
													</Box>
													<Box className={classes.temps} onClick={() => sumarPunts(equip.id, -10)}>
														-10
													</Box>
													<Box className={classes.temps} onClick={() => sumarPunts(equip.id, 10)}>
														+10
													</Box>
													<Box className={classes.temps} onClick={() => sumarPunts(equip.id, 50)}>
														+50
													</Box>
												</Stack>
											</Box>
										</Grid>
									))}
							</Grid>
						</Box>
						<Box className={classes.bloc}>
							<IconButton onClick={() => setOpenResultats(true)}>
								<Fullscreen />
							</IconButton>
							<Charts
								data={equips
									?.filter((e) => e.punts > 0)
									?.sort(function (a, b) {
										return a.punts - b.punts;
									})}
							/>
						</Box>
					</Grid>
					<Grid item md={4}>
						<Box className={classes.bloc}>
							<Box textAlign={"center"}>
								{tempsAcabat() ? (
									<Box textAlign={"center"} mt={2}>
										<Typography variant="h1" mt={2}>
											0:00:00
										</Typography>
										<Typography>Temps acabat</Typography>
									</Box>
								) : (
									<Typography variant="h1" mt={2}>
										{hours}:{minutes <= 9 ? "0" + minutes : minutes}:{seconds <= 9 ? "0" + seconds : seconds}
									</Typography>
								)}
								<Stack direction={"row"} spacing={2} justifyContent={"center"} mt={2}>
									<Box className={classes.temps} onClick={() => addT(-20)}>
										-20
									</Box>
									<Box className={classes.temps} onClick={() => addT(-10)}>
										-10
									</Box>
									<Box className={classes.temps} onClick={() => addT(-5)}>
										-5
									</Box>
									<Box className={classes.temps} onClick={() => addT(-1)}>
										-1
									</Box>
									<Box className={classes.temps} onClick={() => addT(1)}>
										+1
									</Box>
									<Box className={classes.temps} onClick={() => addT(5)}>
										+5
									</Box>
									<Box className={classes.temps} onClick={() => addT(10)}>
										+10
									</Box>
									<Box className={classes.temps} onClick={() => addT(20)}>
										+20
									</Box>
								</Stack>
							</Box>
						</Box>
						<Box className={classes.bloc} mb={3}>
							<Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
								<Typography variant="h3" mt={2}>
									Puntos totales
								</Typography>
								<Typography variant="h2" mt={2}>
									{puntsTotals}
								</Typography>
							</Box>
						</Box>
						<Accions />
					</Grid>
				</Grid>
			</Loading>
			<DialogResultat equips={equips} open={openResultats} setOpen={setOpenResultats} punts={puntsTotals} />
		</PageAdmin>
	);
}

export default HomeAdmin;

const useStyles = makeStyles((theme) => ({
	bloc: {
		backgroundColor: "white",
		padding: 20,
	},
	equip: {
		padding: 10,
		marginBottom: 10,
		border: "1px solid lightgray",
		boxShadow: "#00000020 1px 1px 10px 1px ",
		borderRadius: 5,
	},
	linia: {
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		padding: 10,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	},
	temps: {
		fontFamily: "Rokkitt",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: 30,
		height: 30,
		marign: 7,
		fontSize: 13,
		cursor: "pointer",
		transition: "0.2s",
		backgroundColor: "#C7BDAF",
		borderRadius: 50,
		"&:hover": {
			backgroundColor: "#A13633",
			color: "white",
		},
	},
}));
