import React from "react";
import { Navigate } from "react-router-dom";
import AdminLayout from "./layouts/admin";
import PublicLayout from "./layouts/public";
import HomeAdmin from "./views/admin/HomeAdmin";
import Home from "./views/Home";
import OpcionsAdmin from "./views/admin/OpcionsAdmin";

const routes = (logged) => [
	{
		path: "/admin",
		element: logged ? <AdminLayout /> : <Navigate to="/" />,
		children: [
			{
				path: "/admin/dash",
				element: <HomeAdmin />,
			},
			{
				path: "/admin/opcions",
				element: <OpcionsAdmin />,
			},
		],
	},

	{
		path: "/",
		element: <PublicLayout />,
		children: [
			{
				path: "/",
				element: !logged ? <Home /> : <Navigate to="/admin/dash" />,
			},
		],
	},
];

export default routes;
