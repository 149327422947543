import React from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import NavBarItem from "./NavBarItem";
import { Box, Divider, Drawer, Hidden, List } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { House, Settings } from "@mui/icons-material";
import theme from "../../theme";
import logo from ".././../assets/logo-calavera-footer.png";

const useStyles = makeStyles((theme) => ({
	mobileDrawer: {
		width: 50,
	},
	desktopDrawer: {
		backgroundColor: theme.color.third + " !important",
		width: 50,
		height: "calc(100%)",
	},
	menu: {
		background: theme.color.third,
		textAlign: "center",
	},
}));

const NavBar = ({ onMobileClose, openMobile }) => {
	const classes = useStyles();

	const items = [
		{
			href: "/admin/dash",
			icon: House,
		},
		{
			href: "/admin/opcions",
			icon: Settings,
		},
	];

	const content = (
		<Box height="100%" display="flex" flexDirection="column" className={classes.menu}>
			<Box pt={3}>
				<RouterLink to="/">
					{" "}
					<img alt="Logo Insomnia" width={30} src={logo} />
				</RouterLink>

				<Divider
					style={{
						margin: 8,
						borderColor: "transparent",
					}}
				/>
				<List>
					{items?.map((item, index) =>
						item.href ? (
							<NavBarItem href={item.href} key={index} title={item.title} icon={item.icon} onClose={onMobileClose} />
						) : (
							<Divider
								key={index}
								style={{
									margin: 17,
									borderColor: theme.palette.background.color + "40",
								}}
							/>
						)
					)}
				</List>
			</Box>
			<Box flexGrow={1} />
		</Box>
	);

	return (
		<div>
			<Hidden lgUp>
				<Drawer anchor="left" classes={{ paper: classes.mobileDrawer }} onClose={onMobileClose} open={openMobile} variant="temporary">
					{content}
				</Drawer>
			</Hidden>
			<Hidden lgDown>
				<Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
					{content}
				</Drawer>
			</Hidden>
		</div>
	);
};

NavBar.propTypes = {
	onMobileClose: PropTypes.func,
	openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
	onMobileClose: () => {},
	openMobile: false,
};

export default NavBar;
