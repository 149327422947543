import { createTheme } from "@mui/material/styles";
import { colors } from "@mui/material";
import { createBreakpoints } from "@mui/system";

const breakpoints = createBreakpoints({});

const theme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 960,
			lg: 1150,
			xl: 1350,
		},
	},
	color: {
		primary: "#A13633",
		secondary: "#C7BDAF",
		third: "#1A1918",
		background: "#1A1918",
		background_light: "#C7BDAF",
		alt: "#E9B92D",
	},
	palette: {
		type: "dark",
		background: {
			main: "#1A1918",
			secondary: "#C7BDAF",
			third: "#A13633",
		},
		primary: {
			main: "#1A1918",
			hover: "#1A1918",
		},
		secondary: {
			main: "#C7BDAF",
			hover: "#e32420",
		},
		danger: {
			main: colors.red[500],
			light: colors.red[300],
		},
		success: {
			main: colors.green[500],
		},
		info: {
			main: "#FFF",
			hover: "#e32420",
		},
		text: {
			primary: "#1A1918",
			secondary: "#C7BDAF",
			third: "#E9B92D",
		},
		typography: {
			fontFamily: "Rokkitt",
		},
	},
	typography: {
		h1: {
			fontSize: "4.5rem",
			fontFamily: "Rokkitt",
			color: "#1A1918",
			letterSpacing: 5,
			fontWeight: 300,
			lineHeight: 0.9,
			[breakpoints.down("sm")]: {
				fontSize: "2rem",
			},
			textTransform: "uppercase",
		},
		h2: {
			fontSize: "2.5rem",
			fontFamily: "Rokkitt",
			color: "#1A1918",
			lineHeight: 0.9,
			[breakpoints.down("sm")]: {
				fontSize: "2rem",
			},
			[breakpoints.down("lg")]: {
				fontSize: "2rem",
			},
			textTransform: "uppercase",
			fontWeight: 300,
		},
		h3: {
			fontSize: "1.5rem",
			fontFamily: "Rokkitt",
			textTransform: "uppercase",
			fontWeight: 300,
			color: "#1A1918",
			[breakpoints.down("sm")]: {
				fontSize: "1.2rem",
			},
		},
		h4: {
			fontSize: "1.4rem",
			fontFamily: "Rokkitt",
			textTransform: "uppercase",
			fontWeight: 300,
			color: "#C7BDAF",
			[breakpoints.down("sm")]: {
				fontSize: "1.2rem",
			},
		},
		h5: {
			fontSize: "1.2rem",
			fontFamily: "Rokkitt",
			fontWeight: 300,
			color: "#C7BDAF",
		},
		h6: {
			fontSize: "2rem",
			fontFamily: "Oswald",
			fontWeight: 200,
			lineHeight: 0.9,
			color: "#1A1918",
			textTransform: "uppercase",
			[breakpoints.down("sm")]: {
				fontSize: "1.7rem",
			},
		},
		body1: {
			fontSize: "1.2rem",
			fontFamily: "Rokkitt",
			lineHeight: 1.3,
			color: "#1A1918",
			fontWeight: 300,

			[breakpoints.down("sm")]: {
				fontSize: "1rem",
			},
		},
		body2: {
			fontFamily: "Rokkitt",
			fontSize: "1rem",
			color: "#C7BDAF",
			fontWeight: 300,
		},
		caption: {
			fontFamily: "Rokkitt",
			color: "#C7BDAF",
			fontSize: 14,
		},
		root: {
			"& a": {
				color: "#1D1D1B",
				textDecoration: "none",
				transition: "0.5s",
				"&:hover": {
					color: "black",
				},
			},
		},
		error: {
			fontFamily: "Rokkitt",
			color: "#d32f2f",
			fontSize: "0.9rem",
		},
		title: {
			fontFamily: "Montserrat",
			fontSize: "3.5rem",
			color: "#1D1D1B",
		},
		link: {
			fontSize: "1.3rem",
			fontFamily: "Montserrat",
			fontWeight: 500,
			color: "#1D1D1B",
		},
		footer: {
			fontSize: "1.1rem",
			fontFamily: "Rokkitt",
			color: "#C7BDAF",
		},
		menu: {
			fontFamily: "Rokkitt",
			fontSize: 22,
			textDecoration: "none",
		},
	},
	"& a": {
		color: "black",
	},
});

export default theme;
