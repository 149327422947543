import { Add, Delete, Link } from "@mui/icons-material";
import { Avatar, Divider, Grid, IconButton, Stack, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import moment from "moment";
import React, { useEffect, useState } from "react";
import "moment/locale/ca";
import "moment/min/moment-with-locales";
import { useNavigate } from "react-router";
import PageAdmin from "../../components/PageAdmin";
import Loading from "../../components/Loading";
import { Codis } from "../../db/firebase";
import CustomButton from "../../components/CustomButton";
import { DialogConfirmar } from "../../components/DialogConfirmar";
import Accions from "../../components/Accions";

export default function OpcionsAdmin() {
	const [loading, error, codis, getCodis, addCodi, removeCodi, resetEquips] = Codis();
	const [nouCodi, setNouCodi] = useState();
	const classes = useStyles();

	useEffect(() => {
		getCodis();
	}, []);

	const afegirCodi = () => {
		addCodi(nouCodi);
		setNouCodi("");
	};

	const eliminarCodi = (codi) => {
		removeCodi(codi);
	};

	return (
		<PageAdmin title="El secreto de los Krugger">
			<Loading loading={loading}>
				<Grid spacing={2} container mt={2}>
					<Grid item md={6} xs={12}>
						<Box className={classes.bloc}>
							<Typography variant="h3" mb={2}>
								Còdis vàlids
							</Typography>
							<Grid spacing={3} container>
								{codis?.map((codi) => (
									<Grid item md={3}>
										<Box className={classes.codi}>
											<Typography>{codi.codi}</Typography>
											<IconButton onClick={() => eliminarCodi(codi.codi)}>
												<Delete />
											</IconButton>
										</Box>
									</Grid>
								))}
							</Grid>
							<Box mt={3}>
								<Divider />
							</Box>
							<Box mt={3} display={"flex"} alignItems={"center"}>
								<TextField
									label="Afegir codi"
									name="nouCodi"
									value={nouCodi}
									onChange={(e) => setNouCodi(e.target.value)}
									fullWidth
									inputProps={{ maxLength: 4 }}
								/>
								<IconButton onClick={afegirCodi}>
									<Add />
								</IconButton>
							</Box>
						</Box>
					</Grid>
					<Grid item md={6} xs={12}>
						{/* <Accions /> */}
					</Grid>
				</Grid>
			</Loading>
		</PageAdmin>
	);
}

const useStyles = makeStyles((theme) => ({
	bloc: {
		backgroundColor: "white",
		padding: 20,
	},
	codi: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		padding: 10,
		marginBottom: 10,
		border: "1px solid lightgray",
		boxShadow: "#00000020 1px 1px 10px 1px ",
		borderRadius: 5,
	},
	linia: {
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		padding: 10,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	},
}));
