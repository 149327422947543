import React from "react";
import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Box, Button, ListItem, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
	item: {
		display: "flex",
		paddingTop: 0,
		paddingBottom: 0,
		transition: "0.5s",
	},
	button: {
		marginTop: 5,
		marginBottom: 5,
		// borderRadius: 10,
		justifyContent: "flex-start",
		alignItems: "center",
		letterSpacing: 0,
		transition: "0.5s",
		padding: 10,
		textTransform: "none",
		display: "flex",
		width: "100%",
		textDecoration: "none",
		"&:hover": {
			color: theme.color.secondary,
			"& $icon": {
				color: theme.color.secondary,
			},
			"& $title": {
				color: theme.color.secondary,
			},
		},
		"&.active": {
			backgroundColor: theme.color.primary,
			color: theme.color.secondary,
			boxShadow: "0px 0px 8px 0px " + theme.color.primary,

			"& $title": {
				color: theme.color.secondary,
			},
			"& $icon": {
				color: theme.color.secondary,
			},
			"&:hover": {
				backgroundColor: theme.color.primary,
				color: theme.color.secondary,
				"& $icon": {
					color: theme.color.secondary,
				},
			},
		},
		"& $icon": {
			color: theme.color.primary,
		},
	},
	icon: {
		color: theme.palette.text.secondary,
		marginRight: theme.spacing(1),
	},
	title: {
		color: theme.color.secondary,
	},
}));

const NavBarItem = ({ className, href, icon: Icon, title, onClose, ...rest }) => {
	const classes = useStyles();

	return (
		<ListItem className={classes.item} disableGutters {...rest} sx={{ padding: 0 }}>
			<Box className={classes.button} component={RouterLink} to={href} onClick={onClose} color="secondary">
				<Icon className={classes.icon} size="20" />
			</Box>
		</ListItem>
	);
};

NavBarItem.propTypes = {
	className: PropTypes.string,
	href: PropTypes.string,
	icon: PropTypes.elementType,
	title: PropTypes.string,
};

export default NavBarItem;
