import { Divider, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import "moment/locale/ca";
import "moment/min/moment-with-locales";
import { Codis } from "../db/firebase";
import CustomButton from "./CustomButton";
import { DialogConfirmar } from "./DialogConfirmar";

export default function Accions() {
	const [loading, error, codis, getCodis, addCodi, removeCodi, resetEquips, comencarJoc] = Codis();
	const [openReset, setOpenReset] = useState(false);
	const [setOpenComencar, setOpenComencarJoc] = useState(false);
	const classes = useStyles();

	useEffect(() => {
		getCodis();
	}, []);

	const resetJoc = () => {
		resetEquips();
		setOpenReset(false);
	};

	const iniciJoc = () => {
		resetEquips();
		comencarJoc();
		setOpenComencarJoc(false);
	};

	return (
		<Box className={classes.bloc}>
			<Typography variant="h3" mb={2}>
				Accions
			</Typography>
			<Divider />
			<Stack direction={"row"} spacing={2} mt={3}>
				<CustomButton title={"Començar joc!"} onClick={() => setOpenComencarJoc(true)} fullWidth />
				<CustomButton title={"Resetejar joc"} onClick={() => setOpenReset(true)} danger fullWidth />
			</Stack>
			<DialogConfirmar
				onClick={resetJoc}
				open={openReset}
				setOpen={setOpenReset}
				boto={"Resetejar"}
				text={"Segur que vols resetejar el joc? S'eliminaran totes les puntiacions dels equips actuals"}
			/>
			<DialogConfirmar
				onClick={iniciJoc}
				open={setOpenComencar}
				setOpen={setOpenComencarJoc}
				boto={"Començar"}
				text={"Vols començar un joc nou? Es posarà el temporitzador a +2 hores"}
			/>
		</Box>
	);
}

const useStyles = makeStyles((theme) => ({
	bloc: {
		backgroundColor: "white",
		padding: 20,
	},
}));
