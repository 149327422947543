import { getDatabase, get, ref, set, onValue, query, startAt, orderByChild, push, remove, runTransaction, update, increment } from "firebase/database";
import { getDownloadURL, getStorage, ref as refStorage } from "firebase/storage";
import { getApps, initializeApp } from "firebase/app";
import "firebase/compat/firestore";
import { useEffect, useState } from "react";
import Moment from "moment/min/moment-with-locales";
import moment from "moment/moment";

const firebaseConfig = {
	apiKey: "AIzaSyDI5iu1ewTDJXaXwlRXNQI3UzEGykCHvQw",
	authDomain: "app-scape-insomnia.firebaseapp.com",
	projectId: "app-scape-insomnia",
	storageBucket: "app-scape-insomnia.appspot.com",
	messagingSenderId: "407403353899",
	appId: "1:407403353899:web:511d75d2eb32787a06176e",
	databaseURL: "https://app-scape-insomnia-default-rtdb.europe-west1.firebasedatabase.app",
};

let app;
if (!getApps().length) {
	app = initializeApp(firebaseConfig);
}

const database = getDatabase(app);
const storage = getStorage(app);

// Codis
function Codis() {
	const [codis, setCodis] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	const getCodis = async () => {
		setCodis([]);
		try {
			const dataRef = ref(database, "codis");
			onValue(dataRef, (snapshot) => {
				const news = [];
				snapshot.forEach((item) => {
					news.push({
						codi: item.val().codi,
					});
				});
				setCodis(news?.reverse());
				setLoading(false);
			});
		} catch (e) {
			console.log(e);
			setError(e);
		}
	};

	const removeCodi = async (codi) => {
		remove(ref(database, "codis/" + codi));
	};

	const addCodi = (codi) => {
		set(ref(database, "codis/" + codi), {
			codi,
		});
	};

	const resetEquips = async () => {
		const updates = {};
		updates["temps"] = moment().format("YYYY-MM-DD HH:mm");

		await update(ref(database), updates);

		const dataRef = ref(database, "equips");
		await runTransaction(dataRef, (equips) => {
			let result = {};
			for (let item of Object.keys(equips)) {
				result[item] = { nom: equips[item].nom, punts: 0 };
			}
			return result;
		});
	};

	const comencarJoc = async () => {
		const updates = {};
		updates["temps"] = moment().add("hours", 2).format("YYYY-MM-DD HH:mm");

		update(ref(database), updates);
	};

	return [loading, error, codis, getCodis, addCodi, removeCodi, resetEquips, comencarJoc];
}

// Equips
function Equips() {
	const [equips, setEquips] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	const getEquips = async () => {
		setEquips([]);
		try {
			const dataRef = ref(database, "equips");
			onValue(dataRef, (snapshot) => {
				const news = [];
				let i = 0;
				snapshot.forEach((item) => {
					news.push({
						punts: item.val().punts,
						nom: item.val().nom,
						id: Object.keys(snapshot.val())[i],
					});
					i++;
				});
				setEquips(news?.reverse());
				setLoading(false);
			});
		} catch (e) {
			console.log(e);
			setError(e);
		}
	};

	const sumarPunts = async (equip, punts) => {
		const updates = {};
		updates["equips/" + equip + "/punts"] = increment(punts);

		update(ref(database), updates);
	};

	return [loading, error, equips, getEquips, sumarPunts];
}

// Temps
function Temps() {
	const [temps, setTemps] = useState(null);

	const getTemps = async () => {
		try {
			const dataRef = ref(database, "temps");
			onValue(dataRef, (snapshot) => {
				setTemps(snapshot?.val());
			});
		} catch (e) {
			console.log(e);
		}
	};

	const addMinuts = async (minutes) => {
		try {
			const dataRef = ref(database, "temps");
			runTransaction(dataRef, (temps) => {
				let result;
				if (minutes >= 0) {
					result = moment(temps).add("minutes", minutes).format("YYYY-MM-DD HH:mm");
				} else {
					result = moment(temps).subtract("minutes", Math.abs(minutes)).format("YYYY-MM-DD HH:mm");
				}
				return result;
			});
		} catch (e) {
			console.log(e);
		}
	};

	return [temps, getTemps, addMinuts];
}

export { database, storage, Codis, Equips, Temps };
