import { Link } from "@mui/icons-material";
import { Box, IconButton, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import "moment/locale/ca";
import "moment/min/moment-with-locales";
import { useNavigate } from "react-router";
import PageAdmin from "../components/PageAdmin";
import CustomButton from "../components/CustomButton";
import { useForm } from "react-hook-form";

export default function Home() {
	const navigate = useNavigate();
	const classes = useStyles();
	const [error, setError] = useState("");

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: "onTouched",
	});

	const logInEmail = async (values) => {
		const { email, password } = values;
		if (password === "Insomnia") {
			localStorage.setItem("isLoggedIn", true);
			navigate("/admin/dash");
		} else {
			localStorage.removeItem("isLoggedIn", false);
			setError("Contrasenya incorrecte");
		}
	};

	return (
		<PageAdmin title="Insomnia Corp - Administració App">
			<Box display={"flex"} justifyContent={"center"}>
				<Box className={classes.bloc}>
					<form onSubmit={handleSubmit(logInEmail)}>
						<Box mb={3}>
							<Typography color="textPrimary" variant="h2">
								{"Entrar"}
							</Typography>
							<Typography variant="body1">{"Entra a la pàgina d'administració"}</Typography>
						</Box>

						<TextField
							error={Boolean(errors && errors.password)}
							fullWidth
							helperText={errors && errors?.password?.message}
							label={"Contrasenya"}
							margin="normal"
							name="password"
							type="password"
							variant="outlined"
							{...register("password")}
						/>
						<Typography variant="body1" style={{ color: "red" }}>
							{error}
						</Typography>

						<Box my={2}>
							<CustomButton fullWidth type="submit" title="Entrar" />
						</Box>
					</form>
				</Box>
			</Box>
		</PageAdmin>
	);
}

const useStyles = makeStyles((theme) => ({
	bloc: {
		backgroundColor: "white",
		padding: 20,
		textAlign: "center",
	},
}));
