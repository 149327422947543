import React, { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { AppBar, Avatar, Box, Hidden, IconButton, Toolbar, Typography, useMediaQuery } from "@mui/material";
import { Input, Menu } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import theme from "../../theme";
// import logo from "../../assets/img/logo-calavera-footer.png";

const useStyles = makeStyles((theme) => ({
	root: {
		"& .MuiToolbar-root": {
			// boxShadow: '#00000020 1px 3px 20px 1px ',
			// backgroundColor: "white",
			backgroundColor: theme.color.gradient,
		},
	},
	white: {
		"& .MuiToolbar-root": {
			backgroundColor: theme.color.secondary,
		},
	},
	color: {
		"& .MuiToolbar-root": {
			backgroundColor: theme.palette.background.main,
		},
	},
	logo: {
		height: "70px !important",
		"& .cls-1": {
			fill: theme.palette.background.color,
		},
	},
}));

const TopBarAdmin = ({ className, onMobileNavOpen, ...rest }) => {
	const classes = useStyles();
	let navigate = useNavigate();
	const matches = useMediaQuery("(min-width:960px)");

	const logOut = () => {
		localStorage.removeItem("isLoggedIn");
		navigate("/");
	};

	return (
		<AppBar className={clsx(classes.root, matches ? classes.white : classes.color)} elevation={0} {...rest}>
			<Toolbar>
				<RouterLink to="/">
					{/* <img
						alt="Logo Insomnia"
						width={30}
						src={logo}
						style={{ marginTop: 5 }}
					/> */}
				</RouterLink>
				<Box flexGrow={1} />

				<Hidden>
					<IconButton onClick={() => logOut()}>
						<Input color="primary" />
					</IconButton>
				</Hidden>
				<Hidden mdUp>
					<IconButton color="inherit" onClick={() => onMobileNavOpen()}>
						<Menu style={{ fill: theme.palette.text.secondary }} />
					</IconButton>
				</Hidden>
			</Toolbar>
		</AppBar>
	);
};

TopBarAdmin.propTypes = {
	className: PropTypes.string,
	onMobileNavOpen: PropTypes.func,
};

export default TopBarAdmin;
