import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import CustomButton from "./CustomButton";
import { Charts } from "./Chart";
import logo from "../assets/logo-calavera-footer.png";

export function DialogResultat({ setOpen, open, equips, punts }) {
	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			disableScrollLock
			fullScreen
			PaperProps={{
				style: {
					backgroundColor: "#1A1918",
					boxShadow: "none",
				},
			}}
		>
			<DialogTitle>
				<Box textAlign={"center"} justifyContent={"center"} alignItems={"center"} display={"flex"} flexDirection={"column"} color="white">
					<img src={logo} alt="logo" style={{ width: 80 }} />
					<Typography fontSize={40} color="white">
						Resultados
					</Typography>
					<Typography color="white">
						Total: <strong>{punts} puntos</strong>
					</Typography>
				</Box>
			</DialogTitle>
			<DialogContent>
				<Charts
					height={"100%"}
					color="white"
					data={equips
						?.filter((e) => e.punts > 0)
						?.sort(function (a, b) {
							return a.punts - b.punts;
						})}
				/>
			</DialogContent>
			<DialogActions>
				<CustomButton onClick={() => setOpen(false)} title={"Tancar"} fullWidth />
			</DialogActions>
		</Dialog>
	);
}
