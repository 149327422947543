import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import CustomButton from "./CustomButton";

export function DialogConfirmar({ text, onClick, setOpen, open, boto }) {
	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			disableScrollLock
			maxWidth="md"
		>
			<DialogTitle>Segur?</DialogTitle>
			<DialogContent>
				<Typography>{text}</Typography>
			</DialogContent>
			<DialogActions>
				<CustomButton onClick={() => setOpen(false)} title={"Tancar"} fullWidth />
				<CustomButton onClick={onClick} title={boto} danger fullWidth />
			</DialogActions>
		</Dialog>
	);
}
